'use client';
import React, { useEffect, useState } from 'react';

import { ThemeProvider as NextThemesProvider } from 'next-themes';
import type { ThemeProviderProps } from 'next-themes/dist/types';

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  const [theme, setTheme] = useState<'light' | 'dark' | 'system' | undefined>(undefined);

  useEffect(() => {
    // Check if the system has a preference for light/dark mode
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    const prefersLightScheme = window.matchMedia('(prefers-color-scheme: light)');

    // Apply dark mode if neither light nor dark is explicitly preferred
    if (!prefersDarkScheme.matches && !prefersLightScheme.matches) {
      setTheme('dark');
    }
  }, []);

  return (
    <NextThemesProvider forcedTheme={theme} {...props}>
      {children}
    </NextThemesProvider>
  );
}
