'use client';

import { createContext, useContext, useState, useEffect } from 'react';

import { LANGUAGES_COOKIE, Language, LocaleKey } from '@/constants/locales';

const defaultLanguages: Language[] = ['Detected'];

type LanguageContextType = {
  languages: Language[];
  setLanguages: React.Dispatch<React.SetStateAction<Language[]>>;
};

const LanguageContext = createContext<LanguageContextType>({
  languages: defaultLanguages,
  setLanguages: () => {},
});

export const LanguageProvider = ({ children, languages: l }: { children: React.ReactNode; languages?: Language[] }) => {
  const [languages, setLanguages] = useState<Language[]>(l ?? defaultLanguages);

  useEffect(() => {
    document.cookie = `${LANGUAGES_COOKIE}=${JSON.stringify(languages)}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
  }, [languages]);

  return <LanguageContext.Provider value={{ languages, setLanguages }}>{children}</LanguageContext.Provider>;
};

export function useLanguages() {
  return useContext(LanguageContext);
}

export function useLanguagesWithoutDetect() {
  const output = useContext(LanguageContext);

  return {
    ...output,
    languages: output.languages.filter((lang) => lang !== 'Detected') as LocaleKey[],
  };
}
