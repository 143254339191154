import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/app/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomHighlightStart"] */ "/vercel/path0/components/CustomHighlightStart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BillingProvider"] */ "/vercel/path0/context/BillingContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalProvider"] */ "/vercel/path0/context/GlobalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/vercel/path0/context/LanguageContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"preload\":true}],\"variableName\":\"inter\"}");
